<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../../assets/img/home/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: orgPath }">{{
          breadcrumbName
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>工商社保</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="container">
      <div class="box">
        <div class="search_box">
          <div class="search_title">注册公司前，先查询公司名是否能注册</div>

          <el-form
            :inline="true"
            ref="formInline"
            :rules="rules1"
            :model="formInline"
            class="demo-form-inline"
          >
            <el-form-item>
              <el-input
                v-model="formInline.address"
                placeholder="地址"
                readonly="readonly"
                prefix-icon="el-icon-location-outline"
              ></el-input>
            </el-form-item>
            <el-form-item prop="reserveName">
              <el-input
                v-model="formInline.reserveName"
                placeholder="请输入公司名称"
                prefix-icon="el-icon-edit-outline"
              ></el-input>
            </el-form-item>
            <el-form-item prop="reservePhone">
              <el-input
                v-model="formInline.reservePhone"
                placeholder="请输入手机号码"
                maxlength="11"
                prefix-icon="el-icon-mobile-phone"
              ></el-input>
            </el-form-item>

            <!-- <el-form-item label="活动区域">
              <el-select v-model="formInline.region" placeholder="活动区域">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item>
              <el-button type="primary" @click="onSubmit('formInline')"
                >查询能否注册</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <div class="container container_bg">
      <div class="box">
        <div class="container_title">
          <span>工商注册办理流程</span>
          <span>真正极简快捷流程</span>
          <span>综合化管理只需三步完成注册</span>
        </div>
        <div class="process_list">
          <div class="process_item">
            <div class="process_img">
              <img src="../../../assets/img/home/detail/process1.png" alt="" />
            </div>
            <span>核名设立</span>
          </div>
          <div class="process_item">
            <div class="process_img">
              <img src="../../../assets/img/home/detail/process2.png" alt="" />
            </div>
            <span>预约交件</span>
          </div>
          <div class="process_item">
            <div class="process_img">
              <img src="../../../assets/img/home/detail/process3.png" alt="" />
            </div>
            <span>取照登记</span>
          </div>
        </div>

        <div class="timeline">
          <div class="timeline_item">
            <div class="cir"></div>
            <div class="timeline_content">
              <span>1-2工作日</span>
              <span>核名</span>
            </div>
          </div>
          <div class="timeline_item">
            <div class="cir"></div>
            <div class="timeline_content">
              <span>3-5工作日</span>
              <span>提交文件</span>
            </div>
          </div>
          <div class="timeline_item">
            <div class="cir"></div>
            <div class="timeline_content">
              <span>3-5工作日</span>
              <span>领取营业执照</span>
            </div>
          </div>
          <div class="timeline_item">
            <div class="cir"></div>
            <div class="timeline_content">
              <span>1-3工作日</span>
              <span>刻章/银行开户</span>
            </div>
          </div>
        </div>

        <div class="process_btn" @click="handleRegister">一键托管办理</div>
      </div>
    </div>
    <div class="container">
      <div class="box">
        <div class="container_title">公司相关资料</div>
        <div class="card_box">
          <div class="card_top">
            <div class="card_item">
              <div class="left_card_item">
                <span>注册前</span>
                <span>需准备的资料</span>
                <span>01</span>
              </div>
              <div class="right_card_item">
                <span>公司名称、经营范围</span>
                <span>公司注册资金</span>
                <span>股东出资比例</span>
                <span>全体股东身份证原件及复印件</span>
                <span>法定代表人身份证复印件、电话、邮箱</span>
                <span>经营场所产权证明、租赁协议和房租发票</span>
              </div>
            </div>
            <div class="card_item">
              <div class="left_card_item">
                <span>注册中</span>
                <span>需要用到的资料</span>
                <span>02</span>
              </div>
              <div class="right_card_item">
                <span>《公司注册登记表》</span>
                <span>《场地租赁合同》</span>
                <span>《营业执照经营范围大全》</span>
                <span>《136套创业起步协议模板》</span>
                <span>《154套企业必备合同范本》</span>
                <span>《注册公司必备资料包》</span>
              </div>
            </div>
          </div>
          <div class="card_down">
            <div class="card_item card">
              <div class="left_card_item">
                <span>注册后</span>
                <span>收获的证件资料</span>
                <span>03</span>
              </div>
              <div class="right_card_item">
                <img src="../../../assets/img/home/detail/zs1.png" alt="" />
                <img src="../../../assets/img/home/detail/zs2.png" alt="" />
                <img src="../../../assets/img/home/detail/zs3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container container_bg">
      <div class="box">
        <div class="container_title">支持注册公司类型</div>
        <div class="tab_box">
          <div class="tab_item">
            <div class="tab_head">注册公司行业</div>
            <div class="list">
              <div class="item">餐饮食品业</div>
              <div class="item">商贸零售业</div>
              <div class="item">人力资源业</div>
              <div class="item">医疗药品业</div>
              <div class="item">信息技术</div>
              <div class="item">教育培训业</div>
              <div class="item">建筑业</div>
              <div class="item">文化体育业</div>
              <div class="item">其他行业</div>
              <span></span>
            </div>
          </div>
          <div class="tab_item">
            <div class="tab_head">注册公司类型</div>
            <div class="list">
              <div class="item">有限公司</div>
              <div class="item">个体工商户</div>
              <div class="item">个体独资企业</div>
              <div class="item">有限合伙企业</div>
              <div class="item">股份有限公司</div>
              <div class="item">中外合资企业</div>
              <div class="item">集团公司</div>
              <div class="item">其他公司类型</div>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="box">
        <div class="container_title">免费注册公司</div>
        <div class="register_box">
          <div class="register_list">
            <div class="register_item">
              <div class="top">
                <img src="../../../assets/img/home/detail/icon1.png" alt="" />
              </div>
              <div class="center">
                <span>有限公司注册</span>
                <span>全程代办，快速通道拿证</span>
              </div>
              <div class="btn">
                <a @click="handleRegister"> 一键托管办理 </a>
              </div>
            </div>
            <div class="register_item">
              <div class="top">
                <img src="../../../assets/img/home/detail/icon2.png" alt="" />
              </div>
              <div class="center">
                <span>个体工商户注册</span>
                <span>快速通道拿证，执照快速到家</span>
              </div>
              <div class="btn">
                <a @click="handleRegister"> 一键托管办理 </a>
              </div>
            </div>
            <div class="register_item">
              <div class="top">
                <img src="../../../assets/img/home/detail/icon3.png" alt="" />
              </div>
              <div class="center">
                <span>无地址公司注册</span>
                <span>提供合法注册地址，具体咨询顾问</span>
              </div>
              <div class="btn">
                <a @click="handleRegister"> 一键托管办理 </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 一键办理弹出框 -->
    <el-dialog :visible.sync="dialogVisible" width="30%" center>
      <div class="dialog_head">
        <span>一键托管代办公司注册</span>
        <span>专业顾问全程代办，执照快递到家</span>
      </div>
      <div class="register_phone">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          style="margin-top: 20px"
        >
          <el-form-item prop="reservePhone">
            <el-input
              prefix-icon="el-icon-mobile-phone"
              v-model="form.reservePhone"
              maxlength="11"
              placeholder="请输入手机号码"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 一键办理弹出框 -->
    <el-dialog
      title="预留信息"
      :visible.sync="dialogVisible2"
      width="25%"
      center
      :before-close="dialogColse"
    >
      <div class="dialog_head2">
        <span>我们的客户经理将在1个工作日内与您联系！</span>
      </div>
      <div class="register_phone2">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="10px"
          class="demo-ruleForm"
        >
          <el-form-item prop="reserveName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.reserveName"
              placeholder="请输入称呼昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reservePhone">
            <el-input
              prefix-icon="el-icon-mobile-phone"
              v-model="ruleForm.reservePhone"
              maxlength="11"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reserveEntName">
            <el-input
              prefix-icon="el-icon-office-building"
              v-model="ruleForm.reserveEntName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              v-model="ruleForm.remark"
              placeholder="备注......(200字以内)"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm2('ruleForm')"
              >提交预约</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>

    <el-dialog :visible.sync="dialogSuccess" width="25%" center>
      <div class="success_icon">
        <img src="../../../assets/img/success.png" alt="" />
      </div>
      <div class="success_title">提交成功</div>
      <div class="success_content">
        <p>随后工作人员将与您联系，请保持电话畅通。</p>
        <p>咨询热线：<span>400-666-3369</span></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addReserve } from "../../../api/reserve";

export default {
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      registerPhone: "",
      formInline: {
        address: "太原",
        phone: "",
        acctName: "",
        reserveName: "",
        reservePhone: "",
        remark: "查询能否注册",
      },
      form: {},
      // 表单校验
      org: "",
      orgPath: "",
      breadcrumbName: "",

      dialogSuccess: false,
      dialogVisible2: false,
      registerPhone: "",
      ruleForm: {
        reserveName: "",
        reservePhone: "",
        reserveEntName: "",
        remark: "",
      },
      rules: {
        reserveName: [
          { required: true, message: "请输入预约人名称", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        reservePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
      rules1: {
        reserveName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
          // { min: 2, max: 7, message: "长度在 2 到 7 个字符", trigger: "blur" },
        ],
        reservePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formInline.reserveType = "01";
          addReserve(this.formInline).then((response) => {
            this.dialogSuccess = true;
          });
        }
      });
    },
    reset() {
      this.form = {
        reservePhone: "",
      };
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
    // 一键办理注册
    handleRegister() {
      this.reset();
      this.dialogVisible = true;
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.reserveType = "01";
          addReserve(this.form).then((response) => {
            // if (response.code == 200) {
            //   // this.$modal.msgSuccess("意见办理注册预约成功");
            // }
            this.dialogSuccess = true;
            this.dialogVisible = false;
          });
        }
      });
    },

    // 体验信息提交
    handleApply() {
      this.dialogVisible2 = true;
    },
    subInfo() {
      this.$router.push({
        path: "/info",
        query: { org: "sz" },
      });
    },
    submitForm2(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.reserveType = "02";
          addReserve(this.ruleForm).then((response) => {
            // if (response.code == 200) {
            //   // this.$modal.msgSuccess("意见办理注册预约成功");

            // }
            this.dialogSuccess = true;
            this.dialogVisible2 = false;
          });
        }
      });
    },
    dialogColse() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible2 = false;
    },
    // 取消按钮
    cancel() {
      this.dialogVisible = false;
      this.reset();
    },
  },
  created() {
    this.org = this.$route.query.org;
    if (this.org == "home") {
      this.orgPath = "/";
      this.breadcrumbName = "首页";
    } else {
      this.orgPath = "/enterpriseService";
      this.breadcrumbName = "基础服务";
    }
  },
  mounted() {},
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
  padding: 60px;
}

.container_bg1 {
  background: #f5f5f5;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.container_title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.container_title span {
  display: block;
  margin: 20px 0;
}

.container_title span:first-child {
  font-size: 18px;
}

.container_title span:nth-child(2) {
  font-size: 24px;
}

.container_title span:nth-child(3) {
  font-size: 18px;
  color: #949494;
}

.search_box {
  width: 90%;
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
    0 4px 8px 0 rgba(232, 237, 250, 0.5);
  margin: 0 auto;
  border-radius: 120px;
}

.search_title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 20px;
}

.el-form {
  text-align: center;
}

.search_box .el-button {
  background: #195bf8;
}

.process_list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 80px;
}

.process_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.process_item span {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  margin-top: 20px;
}

.process_img {
  text-align: center;
}

.process_img img {
  width: 80%;
}

.timeline {
  width: 90%;
  margin: 0px auto;
  border-top: 1px solid #055afe;
  display: flex;
  align-items: center;
  margin-top: 100px;
}

.timeline_item {
  position: relative;
  width: 25%;
  top: -18px;
}

.cir {
  width: 35px;
  height: 35px;
  border: 5px solid #055afe;
  border-radius: 50%;
  background: #fff;
  margin: 0 auto;
}

.timeline_content {
  padding-top: 20px;
}

.timeline_content span {
  display: block;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.process_btn {
  width: 132px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 28px;
  background: #055afe;
  cursor: pointer;
  color: #fff;
  margin: 0 auto;
  margin-top: 60px;
}

.card_box {
  width: 100%;
  margin-top: 40px;
}

.card_top {
  display: flex;
  justify-content: space-between;
}

.card_item {
  display: flex;
  width: 588px;
  height: 257px;
  padding: 35px 75px;
  border-radius: 10px;
  border: 1px solid #055afe;
}

.left_card_item span {
  display: block;
}

.left_card_item span:first-child {
  font-size: 24px;
  color: #333;
  font-weight: 600;
}

.left_card_item span:nth-child(2) {
  font-size: 16px;
  color: #333;
  margin: 5px 0;
}

.left_card_item span:nth-child(3) {
  font-size: 24px;
  color: #999;
  font-weight: 600;
  margin-top: 10px;
}

.right_card_item {
  flex: 1;
  margin-left: 20px;
  padding-left: 30px;
  border-left: 1px solid #c6c6c6;
}

.right_card_item span {
  display: block;
  margin-top: 14px;
}

.right_card_item span:first-child {
  margin-top: 0;
}

.card {
  width: 100%;
  margin-top: 20px;
}

.right_card_item img {
  margin-left: 90px;
}

.tab_box {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.tab_item {
  width: 588px;
  height: 242px;
  background: #ffffff;
  border-radius: 10px;
}

.tab_head {
  height: 43px;
  line-height: 43px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background: #055afe;
  border-radius: 10px;
  padding: 0 27px;
}

.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 30px;
}

.tab_item .item {
  width: 163px;
  height: 40px;
  margin-top: 16px;
  font-size: 14px;
  color: #333;
  text-align: center;
  line-height: 40px;
  border-radius: 10px;
  border: 1px solid #dadada;
}

.list span {
  width: 163px;
}

.register_box {
  margin-top: 40px;
}

.register_list {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.register_item {
  width: 384px;
  height: 317px;
  border-radius: 10px;
  border: 1px solid #979797;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
}

.top {
  width: 100px;
  height: 100px;
}

.top img {
  display: block;
  width: 100%;
}

.center span {
  display: block;
  margin-top: 12px;
}

.center span:first-child {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.center span:nth-child(2) {
  font-size: 14px;
  color: #333;
}

.btn {
  width: 132px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 28px;
  border: 1px solid #055afe;
  margin-top: 20px;
  cursor: pointer;
}

.btn a {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 28px;
}

.btn:hover a {
  background: #055afe;
  color: #fff;
}

.dialog_head {
}

.dialog_head span {
  display: block;
  width: 100%;
  text-align: center;
}

.dialog_head span:first-child {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 20px;
}

.register_phone {
  width: 300px;
  margin: 0px auto;
  margin-top: 20px;
}

.dialog_head2 {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #055afe;
  margin-bottom: 20px;
}

.success_icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.success_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.success_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 30px 0;
}

.success_content {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #919191;
}
.success_content p span {
  color: #055afe;
}
</style>
